<template>
  <s-alert
    v-if="isVisible"
    class="s-form-submit-success"
    color="success"
    :prefix-icon="faCircleInfo"
    :suffix-icon="faCircleCheck"
  >
    <span v-html="$t('submitSuccess.text', { url: href })" />
  </s-alert>
</template>

<script setup lang="ts">
import { faCircleCheck, faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { applyArgusUuidToUrl } from "@solvari/common-fe/argus";
import { computed } from "vue";

import { SAlert } from "@solvari/common-fe";

import { useApplicationStore } from "@/plugins/store/application";
import { useFormStore } from "@/plugins/store/form";

const applicationStore = useApplicationStore();
const formStore = useFormStore();

const isVisible = computed(
  () => applicationStore.submitResult && !formStore.isCrossSellForm,
);

const href = computed(() => {
  if (!applicationStore.submitResult?.redirectUrl) {
    return;
  }
  return applyArgusUuidToUrl(applicationStore.submitResult.redirectUrl);
});
</script>
