import {
  emitComponentLoaded,
  getSiteLocale,
  initOnPrefill,
} from "@solvari/common-fe/helpers";
import { createPinia, PiniaVuePlugin } from "pinia";
import Vue, { reactive } from "vue";

import FormRoot from "@/components/FormRoot.vue";
import { initEnv } from "@/helpers/env";
import { getProps, getRootElement } from "@/helpers/rootElement";
import { initI18n } from "@/plugins/i18n";
import { initSentry } from "@/plugins/sentry";

function main() {
  initEnv();
  initSentry();

  Vue.config.productionTip = false;
  Vue.use(PiniaVuePlugin);

  const urlSearchParams = new URLSearchParams(window.location.search);

  const urlPrefills = {
    zipcode: urlSearchParams.get("zip_code"),
    houseNumber: urlSearchParams.get("house_nr"),
    city: urlSearchParams.get("city"),
    street: urlSearchParams.get("street"),
  };

  const rootElement = getRootElement();
  const datasetProps = getProps(rootElement);

  const props = reactive({
    locale: getSiteLocale(),
    ...urlPrefills,
    ...datasetProps,
  });

  initOnPrefill(props);

  const vueInstance = new Vue({
    pinia: createPinia(),
    i18n: initI18n(props.locale),
    render: (create) => create(FormRoot, { props }),
  });

  vueInstance.$mount(rootElement);

  emitComponentLoaded("solvari-form");
}

main();
