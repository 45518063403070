<template>
  <s-button-group
    v-model="model"
    class="form-question"
    :items="answers"
    name="gender"
    required
    show-required-type="none"
    v-on="gtmListeners"
  />
</template>

<script setup lang="ts">
import { useI18n } from "@solvari/common-fe/composables";
import { toRef } from "vue";

import { SButtonGroup } from "@solvari/common-fe";

import { useGtmListeners } from "@/composables/useGtmListeners";
import { useLeadStore } from "@/plugins/store/lead";

const leadStore = useLeadStore();
const { tr } = useI18n();
const gtmListeners = useGtmListeners();

const answers = [
  {
    label: tr(`fields.mr`),
    value: "mr",
  },
  {
    label: tr(`fields.mrs`),
    value: "mrs",
  },
];

const model = toRef(leadStore, "title");
</script>
