<template>
  <s-file-input
    v-bind="questionLogic.props"
    accept=".jpg, .jpeg, .png, .webp, .gif, .avif, .svg, .tif, .tiff, .bmp, .pdf"
    class="form-question"
    max-size="1000"
    multiple
    :rules="[maxAmount(5, { events: ['fileUpload'] })]"
    v-on="{ ...gtmListeners, ...questionLogic.on }"
  />
</template>

<script setup lang="ts">
import type { SFileLocal } from "@solvari/common-fe/composables";
import type { PropType } from "vue";

import { maxAmount } from "@solvari/common-fe/validation";
import { toRef } from "@vueuse/core";

import { SFileInput } from "@solvari/common-fe";

import type { FormComponentInput } from "@/helpers/types";

import { useGtmListeners } from "@/composables/useGtmListeners";
import { useQuestionLogic } from "@/composables/useQuestionLogic";

const props = defineProps({
  question: {
    type: Object as PropType<FormComponentInput>,
    required: true,
  },
});

const questionLogic = useQuestionLogic<SFileLocal[] | undefined>(
  toRef(props, "question"),
);
const gtmListeners = useGtmListeners(toRef(questionLogic.props, "label"));
</script>
