<template>
  <s-number-input
    v-bind="questionLogic.props"
    class="form-question"
    v-on="{ ...gtmListeners, ...questionLogic.on }"
  />
</template>

<script setup lang="ts">
import type { PropType } from "vue";

import { toRef } from "vue";

import { SNumberInput } from "@solvari/common-fe";

import type { FormComponentInput } from "@/helpers/types";

import { useGtmListeners } from "@/composables/useGtmListeners";
import { useQuestionLogic } from "@/composables/useQuestionLogic";

const props = defineProps({
  question: {
    type: Object as PropType<FormComponentInput>,
    required: true,
  },
});

const gtmListeners = useGtmListeners();
const questionLogic = useQuestionLogic<number | undefined>(
  toRef(props, "question"),
);
</script>
