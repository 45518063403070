<template>
  <div class="form-question">
    <s-text-input
      v-model="phone"
      class="s-form__phone"
      :maxlength="255"
      name="phone"
      required
      show-required-type="none"
      type="tel"
      v-on="gtmListeners"
    />
    <div v-if="locale === 'nl-NL' && isNlPhone" class="s-form__whatsapp">
      <font-awesome-icon class="s-form__whatsapp__icon" :icon="faWhatsapp" />
      <s-checkbox
        v-model="whatsappAgreement"
        name="whatsappAgreement"
        v-on="gtmListeners"
        @input="gtmStore.whatsappOptIn()"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useI18n } from "@solvari/common-fe/composables";
import { computed, toRef } from "vue";

import { SCheckbox, STextInput } from "@solvari/common-fe";

import { useGtmListeners } from "@/composables/useGtmListeners";
import { useGtmStore } from "@/plugins/store/gtm";
import { useLeadStore } from "@/plugins/store/lead";

const leadStore = useLeadStore();
const gtmStore = useGtmStore();
const gtmListeners = useGtmListeners();
const { locale } = useI18n();

const phone = toRef(leadStore, "phone");
const isNlPhone = computed(() => {
  const sanitised = phone.value.replaceAll(/[^1-9]/g, "");
  return sanitised.startsWith("6") || sanitised.startsWith("316");
});
const whatsappAgreement = toRef(leadStore, "whatsappAgreement");
</script>

<style lang="postcss">
.s-form__phone {
  @apply mb-2;
}
.s-form__whatsapp {
  @apply flex items-center gap-2;
  &__icon {
    @apply h-4 w-4 rounded-full bg-accent-400 p-1 text-white;
  }
}
</style>
