import { toCamel } from "ts-case-convert";

export type Condition =
  | "EQUAL"
  | "GREATER_THAN_OR_EQUAL"
  | "GREATER_THAN"
  | "LESS_THAN_OR_EQUAL"
  | "LESS_THAN";

export function checkDynamicCondition(
  value1: number | string | null,
  condition: Condition,
  value2: number | string,
) {
  if (value1 === null) {
    return false;
  }

  return {
    equal: () => value1 === value2,
    lessThan: () => value1 < value2,
    greaterThan: () => value1 > value2,
    lessThanOrEqual: () => value1 <= value2,
    greaterThanOrEqual: () => value1 >= value2,
  }[toCamel(condition.toLowerCase() as Lowercase<Condition>)]();
}
