<template>
  <s-address
    :available-locales="availableLocales"
    :city.sync="city"
    class="form-question"
    :house-number.sync="houseNumber"
    :locale.sync="locale"
    :street.sync="street"
    :zipcode.sync="zipcode"
    v-on="gtmListeners"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, toRef } from "vue";

import { SAddress } from "@solvari/common-fe";

import { useGtmListeners } from "@/composables/useGtmListeners";
import { useFormStore } from "@/plugins/store/form";
import { useLeadStore } from "@/plugins/store/lead";

const formStore = useFormStore();
const gtmListeners = useGtmListeners();
const availableLocales = toRef(formStore, "availableLocales");

const locale = computed({
  get: () => formStore.locale!,
  set: (value) => (formStore.formGetProps.locale = value),
});

const leadStore = useLeadStore();
const { zipcode, houseNumber, city, street } = storeToRefs(leadStore);
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
