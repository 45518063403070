<template>
  <div class="form-question">
    <s-email
      v-model="email"
      :locale="localeIso"
      name="email"
      required
      show-required-type="none"
      v-on="gtmListeners"
    />
    <s-checkbox v-model="newsletter" name="newsletter" v-on="gtmListeners" />
  </div>
</template>

<script setup lang="ts">
import type { LocaleIso } from "@solvari/common-fe/helpers";

import { useI18n } from "@solvari/common-fe/composables";
import { toRef } from "@vueuse/core";

import { SCheckbox, SEmail } from "@solvari/common-fe";

import { useGtmListeners } from "@/composables/useGtmListeners";
import { useLeadStore } from "@/plugins/store/lead";

const { locale } = useI18n();
const localeIso = toRef(() => locale.value as LocaleIso);

const gtmListeners = useGtmListeners();
const leadStore = useLeadStore();
const email = toRef(leadStore, "email");
const newsletter = toRef(leadStore, "newsletter");
</script>
