import type { LocaleIso } from "@solvari/common-fe/helpers";
import type { LocaleMessageObject } from "vue-i18n";

import Vue from "vue";
import VueI18n from "vue-i18n";
import { createI18n } from "vue-i18n-composable";

import { useAbTestsStore } from "@/plugins/store/abTests";
import { useMetaStore } from "@/plugins/store/meta";

Vue.use(VueI18n);

let i18nInstance: VueI18n | null = null;

function getI18nInstance() {
  if (!i18nInstance) {
    throw new Error("Tried to use i18n instance before init");
  }
  return i18nInstance;
}

function initI18n(locale: LocaleIso) {
  i18nInstance = createI18n({
    locale,
    fallbackLocale: locale,
    silentTranslationWarn: true,
    messages: {
      "nl-NL": {
        loadingError: {
          title: "Oeps! Er gaat iets mis met het ophalen van dit formulier.",
          text: "Probeer het later nog eens. Onze excuses voor het ongemak!",
        },
      },
      "nl-BE": {
        loadingError: {
          title: "Oeps! Er gaat iets mis met het ophalen van dit formulier.",
          text: "Probeer het later nog eens. Onze excuses voor het ongemak!",
        },
      },
      "fr-BE": {
        loadingError: {
          title: "Oups ! Nous n’arrivons pas à charger ce formulaire.",
          text: "Veuillez réessayer plus tard. Désolé pour le désagrément !",
        },
      },
      "fr-FR": {
        loadingError: {
          title: "Oups ! Nous n’arrivons pas à charger ce formulaire.",
          text: "Veuillez réessayer plus tard. Désolé pour le désagrément !",
        },
      },
    },
  });

  return i18nInstance;
}

function setLocaleMessages(
  locale: LocaleIso,
  translations: LocaleMessageObject,
) {
  const i18n = getI18nInstance();
  i18n.locale = locale;
  i18n.setLocaleMessage(locale, translations);

  const translationsProp = useMetaStore().translations?.[locale];
  if (translationsProp) {
    i18n.mergeLocaleMessage(locale, translationsProp);
  }

  const abTestTranslations = useAbTestsStore().translations?.[locale];
  if (abTestTranslations) {
    i18n.mergeLocaleMessage(locale, abTestTranslations);
  }
}

export { initI18n, setLocaleMessages };
