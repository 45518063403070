<template>
  <s-button-group
    v-bind="questionLogic.props"
    class="form-question"
    v-on="{ ...gtmListeners, ...questionLogic.on }"
  >
    <template #checkbox-radio-group="checkboxRadioGroupSlot">
      <s-checkbox-radio-image-group
        v-bind="checkboxRadioGroupSlot.props"
        v-on="checkboxRadioGroupSlot.on"
      />
    </template>
  </s-button-group>
</template>

<script setup lang="ts">
import type { PropType } from "vue";

import { toRef } from "vue";

import { SButtonGroup, SCheckboxRadioImageGroup } from "@solvari/common-fe";

import type { FormComponentWithAnswer } from "@/helpers/types";

import { useGtmListeners } from "@/composables/useGtmListeners";
import { useQuestionLogic } from "@/composables/useQuestionLogic";

const props = defineProps({
  question: {
    type: Object as PropType<FormComponentWithAnswer>,
    required: true,
  },
});

const questionLogic = useQuestionLogic<number | string | undefined>(
  toRef(props, "question"),
);
const gtmListeners = useGtmListeners(toRef(questionLogic.props, "label"));
</script>
