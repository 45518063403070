<template>
  <div>
    <form-recent-application v-if="!stepperStore.previousStep" />
    <form-cross-sell-alert v-if="!stepperStore.previousStep" />
    <review-stars v-if="!stepperStore.nextStep" />

    <form-component
      v-for="component of step.components"
      :key="component.id"
      :component="component"
    />

    <form-legal-disclaimer v-if="!stepperStore.nextStep" />
  </div>
</template>

<script setup lang="ts">
import { toRef } from "@vueuse/core";

import FormCrossSellAlert from "@/components/meta/FormCrossSellAlert.vue";
import FormRecentApplication from "@/components/meta/FormRecentApplication.vue";
import ReviewStars from "@/components/meta/ReviewStars.vue";
import { useStepperStore } from "@/plugins/store/stepper";

import FormComponent from "./FormComponent.vue";
import FormLegalDisclaimer from "./input/preset/FormLegalDisclaimer.vue";

const stepperStore = useStepperStore();

const step = toRef(() => stepperStore.currentFormStep);
</script>

<style lang="postcss">
.s-form {
  &-review-stars,
  &-recent-application,
  &-cross-sell-alert {
    @apply mb-4;
  }
}
</style>
