import {
  addSentryProject,
  trackVueErrors,
} from "@solvari/common-fe/integrations";
import Vue from "vue";

import { getEnv } from "@/helpers/env";
import { useFormStore } from "@/plugins/store/form";
import { useLeadStore } from "@/plugins/store/lead";

export function initSentry() {
  addSentryProject({
    addData: () => {
      const formStore = useFormStore();
      const leadStore = useLeadStore();

      return {
        tags: {
          domain: window.location.hostname,
          formGroupId: formStore.formGroupId,
          formId: formStore.formId,
          locale: formStore.locale,
        },
        contexts: {
          form: {
            name: formStore.name,
            locale: formStore.locale,
            formGroupId: formStore.formGroupId,
            formId: formStore.formId,
            revisionId: formStore.revisionId,
            availableLocales: formStore.availableLocales,
          },
          lead: leadStore.getLead,
        },
      };
    },
    dsn: getEnv().integrations.sentry,
    environment: getEnv().config.envFE,
    name: "forms",
    path: `${getEnv().network.cdn.microFrontends}/${getEnv().network.cdn.path}`,
    version: getEnv().config.version,
  });

  trackVueErrors(Vue);
}
