<template>
  <div class="s-form-loading">
    <font-awesome-icon class="s-form-loading__icon" :icon="faSpinnerThird" />
    <template v-if="isSwitchingLocale">
      <div class="s-form-loading__title">{{ $t("loading.title") }}</div>
      <div v-if="$t('loading.text')" class="s-form-loading__text">
        {{ $t("loading.text") }}
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";

import { useFormStore } from "@/plugins/store/form";

const formStore = useFormStore();
const isSwitchingLocale = computed(() => !!formStore.availableLocales.length);
</script>

<style lang="postcss">
.s-form-loading {
  animation: form-loading 1s cubic-bezier(0.4, 0, 0.6, 1) infinite alternate;
  @apply flex h-104 w-full flex-col items-center justify-center rounded;

  &__icon {
    @apply h-10 w-10 animate-spin text-primary-700;
  }

  &__title,
  &__text {
    @apply w-128 max-w-full text-center text-base;
  }

  &__title {
    @apply mt-8 font-bold text-gray-800;
  }

  &__text {
    @apply mt-4 text-gray-600;
  }

  &.fade {
    &-enter-active,
    &-leave-active {
      @apply absolute top-0;
    }
  }
}

@keyframes form-loading {
  0% {
    @apply bg-gray-50;
  }
  100% {
    @apply bg-gray-100;
  }
}
</style>
