<template>
  <component
    :is="getActiveComponent(component.type)"
    v-if="!mayHaveChildComponents"
    :question="component"
  />

  <div v-else>
    <component :is="getActiveComponent(component.type)" :question="component" />

    <template v-if="hasChildComponents">
      <form-component
        v-for="childComponent of childComponents"
        :key="childComponent?.id"
        :component="childComponent"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from "vue";

import { computed } from "vue";

import type { FormComponentAll } from "@/helpers/types";

import FormCheckbox from "@/components/input/FormCheckbox.vue";
import FormCheckboxImage from "@/components/input/FormCheckboxImage.vue";
import FormFile from "@/components/input/FormFile.vue";
import FormNumber from "@/components/input/FormNumber.vue";
import FormRadio from "@/components/input/FormRadio.vue";
import FormRadioImage from "@/components/input/FormRadioImage.vue";
import FormSelect from "@/components/input/FormSelect.vue";
import FormText from "@/components/input/FormText.vue";
import FormDescription from "@/components/input/preset/FormDescription.vue";
import FormEmail from "@/components/input/preset/FormEmail.vue";
import FormFirstName from "@/components/input/preset/FormFirstName.vue";
import FormGender from "@/components/input/preset/FormGender.vue";
import FormLastName from "@/components/input/preset/FormLastName.vue";
import FormPhone from "@/components/input/preset/FormPhone.vue";
import FormZipcode from "@/components/input/preset/FormZipcode.vue";
import FormHeader from "@/components/meta/FormHeader.vue";
import FormHtmlBlock from "@/components/meta/FormHtmlBlock.vue";
import { useLeadStore } from "@/plugins/store/lead";

const props = defineProps({
  component: {
    type: Object as PropType<FormComponentAll>,
    required: true,
  },
});

const leadStore = useLeadStore();

function getChildComponents(answerValue: number | string) {
  if (!("answers" in props.component)) {
    return [];
  }
  const answer = props.component.answers.find(
    ({ value }) => value === answerValue,
  );
  if (!answer) {
    return [];
  }
  return answer.childComponents;
}

const childComponents = computed(() => {
  const additional = leadStore.additional[props.component.id];

  if (!additional) {
    return [];
  }

  if (Array.isArray(additional)) {
    // TODO: additional can also be a file array, but that will never have answers. Fixing is more work
    return (additional as number[] | string[]).flatMap(
      (value) => getChildComponents(value) ?? [],
    );
  }

  return getChildComponents(additional) ?? [];
});

const mayHaveChildComponents = computed(() => {
  if (!("answers" in props.component)) {
    return false;
  }
  return props.component.answers.some(
    ({ childComponents }) => !!childComponents,
  );
});

const hasChildComponents = computed(() => childComponents.value.length > 0);

const getActiveComponent = (type: FormComponentAll["type"]) => {
  return {
    /* eslint-disable @typescript-eslint/naming-convention */
    FormText,
    FormNumber,
    FormFile,
    FormDescription,
    FormEmail,
    FormFirstName,
    FormGender,
    FormLastName,
    FormPhone,
    FormZipcode,
    FormHeader,
    FormHtmlBlock,
    FormCheckbox,
    FormCheckboxImage,
    FormRadio,
    FormRadioImage,
    FormSelect,
    /* eslint-enable @typescript-eslint/naming-convention */
  }[type];
};
</script>

<style lang="postcss">
.form-question {
  @apply mb-8;
  scroll-margin-top: 2rem;
}
</style>
