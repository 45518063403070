import type { SFileLocal } from "@solvari/common-fe/composables";
import type { ValidationRuleBase } from "@solvari/common-fe/validation";
import type { Ref } from "vue";

import { toRef } from "@vueuse/core";
import { computed, reactive } from "vue";

import type {
  FormComponentInput,
  FormComponentWithAnswer,
} from "@/helpers/types";

import { blocker } from "@/helpers/rules";
import { useLeadStore } from "@/plugins/store/lead";

export function useQuestionLogic<
  Value extends
    | number[]
    | SFileLocal[]
    | string[]
    | number
    | string
    | undefined,
>(question: Ref<FormComponentInput | FormComponentWithAnswer>) {
  const leadStore = useLeadStore();

  leadStore.setDefaultAdditional(question.value);

  const model = computed(
    () => leadStore.additional[question.value.id] as Value,
  );

  function setValue(value: Exclude<Value, undefined>) {
    leadStore.setAdditional({
      question: question.value,
      value,
    });
  }

  const computedRules = computed(() => {
    return question.value.validation?.reduce((appliedRules, rule) => {
      if (rule.name === "blocker") {
        appliedRules.push(blocker(rule.params));
      }
      return appliedRules;
    }, [] as ValidationRuleBase<unknown>[]);
  });

  const name = computed(() => question.value.id.toString());

  const required = computed(() => {
    return !!question.value.validation?.find(({ name }) => name === "required");
  });

  const items = computed(() => {
    if ("answers" in question.value) {
      return question.value.answers;
    }
    return undefined;
  });

  return {
    props: reactive({
      value: model,
      name,
      label: toRef(() => question.value.label),
      placeholder: toRef(() => question.value.placeholder ?? undefined),
      description: toRef(() => question.value.description),
      tooltip: toRef(() => question.value.tooltip),
      rules: computedRules,
      required,
      showRequiredType: "none" as const,
      items,
    }),
    on: {
      input: setValue,
    },
  };
}
