<template>
  <s-textarea
    v-model="model"
    class="form-question"
    :maxlength="950"
    name="description"
    :placeholder="props.question.placeholder"
    :required="localeIso === 'fr-FR'"
    show-required-type="required"
    v-on="gtmListeners"
  />
</template>

<script setup lang="ts">
import type { LocaleIso } from "@solvari/common-fe/helpers";
import type { PropType } from "vue";

import { useI18n } from "@solvari/common-fe/composables";
import { toRef } from "@vueuse/core";

import { STextarea } from "@solvari/common-fe";

import type { FormComponentInput } from "@/helpers/types";

import { useGtmListeners } from "@/composables/useGtmListeners";
import { useLeadStore } from "@/plugins/store/lead";

const props = defineProps({
  question: {
    type: Object as PropType<FormComponentInput>,
    required: true,
  },
});

const { locale } = useI18n();
const localeIso = toRef(() => locale.value as LocaleIso);

const gtmListeners = useGtmListeners();
const leadStore = useLeadStore();
const model = toRef(leadStore, "description");
</script>
