<template>
  <s-text-input
    v-model="model"
    class="form-question"
    :maxlength="255"
    name="firstName"
    required
    show-required-type="none"
    v-on="gtmListeners"
  />
</template>

<script setup lang="ts">
import { toRef } from "vue";

import { STextInput } from "@solvari/common-fe";

import { useGtmListeners } from "@/composables/useGtmListeners";
import { useLeadStore } from "@/plugins/store/lead";

const gtmListeners = useGtmListeners();
const leadStore = useLeadStore();
const model = toRef(leadStore, "firstName");
</script>
