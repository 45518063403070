import type { PartialDeep } from "type-fest";

import {
  getDotEnv,
  getEnv as getEnvCommon,
  initEnv as initEnvCommon,
} from "@solvari/common-fe/helpers";

const env = {
  config: {
    envFE: import.meta.env.MODE as "development" | "production" | "staging",
    version: getDotEnv(
      "npm_package_version",
      import.meta.env.npm_package_version,
    ),
  },
  network: {
    api: {
      base: getDotEnv(
        "VITE_SOLVARI_API_URL",
        import.meta.env.VITE_SOLVARI_API_URL,
      ),
    },
    argus: {
      base: getDotEnv("VITE_ARGUS_API_URL", import.meta.env.VITE_ARGUS_API_URL),
    },
    cdn: {
      microFrontends: getDotEnv(
        "VITE_SOLVARI_CDN_MICRO_FRONTENDS",
        import.meta.env.VITE_SOLVARI_CDN_MICRO_FRONTENDS,
      ),
      path: getDotEnv(
        "VITE_SOLVARI_CDN_PATH",
        import.meta.env.VITE_SOLVARI_CDN_PATH,
      ),
    },
  },
  integrations: {
    sentry: import.meta.env.VITE_SENTRY_DSN as string,
    pusher: {
      appId: getDotEnv("VITE_PUSHER_ID", import.meta.env.VITE_PUSHER_ID),
      key: getDotEnv("VITE_PUSHER_KEY", import.meta.env.VITE_PUSHER_KEY),
      cluster: getDotEnv(
        "VITE_PUSHER_CLUSTER",
        import.meta.env.VITE_PUSHER_CLUSTER,
      ),
    },
  },
};

const getEnv = getEnvCommon<typeof env>;
const initEnv = () => initEnvCommon(env, window.solvariEnv ?? {});

export { getEnv, initEnv };
export type SolvariEnv = PartialDeep<ReturnType<typeof getEnv>>;
