import { defineStore } from "pinia";
import { computed } from "vue";

import { constructStore } from "@/composables/constructStore";
import { useAbTestsStore } from "@/plugins/store/abTests";

export interface FeatureFlags {
  crossSell?: boolean;
  exitIntent?: boolean;
  highlightOnFocus?: boolean;
  reviewStars?: boolean;
}

/*
  Stores which features should be toggled on or off, booleans only
 */
const { init: initFeatureFlagStore, use: useFeatureFlagStore } = constructStore(
  (props: Readonly<FeatureFlags>) =>
    defineStore("featureFlags", () => {
      const abTestStore = useAbTestsStore();

      const highlightOnFocus = computed(() => props.highlightOnFocus ?? true);
      const exitIntent = computed(
        () => abTestStore.exitIntent ?? props.exitIntent ?? false,
      );
      const reviewStars = computed(
        () => abTestStore.reviewStars ?? props.reviewStars ?? false,
      );
      const crossSell = computed(
        () => abTestStore.crossSell ?? props.crossSell ?? false,
      );

      return { highlightOnFocus, exitIntent, reviewStars, crossSell };
    }),
);

export { initFeatureFlagStore, useFeatureFlagStore };
