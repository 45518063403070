<template>
  <stepper-minimal
    v-if="hasMultipleSteps"
    v-model="model"
    class="form-stepper"
    :max="formStore.steps.length"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";

import { StepperMinimal } from "@solvari/common-fe";

import { useFormStore } from "@/plugins/store/form";
import { useStepperStore } from "@/plugins/store/stepper";

const stepperStore = useStepperStore();
const formStore = useFormStore();

const model = computed({
  get: () => stepperStore.currentStep + 1,
  set: (value) => stepperStore.goToStep(value - 1),
});

const hasMultipleSteps = computed(() => formStore.steps.length > 1);
</script>

<style lang="postcss">
.form-stepper {
  @apply mb-12;
}
</style>
