<template>
  <s-alert
    v-if="featureFlagStore.reviewStars"
    class="s-form-review-stars"
    color="primary"
  >
    <div class="s-form-review-stars__content">
      <s-review-score-atom
        :icon="faCircleStar"
        :show-number-score="false"
        :value="4.5"
      />
      <span>
        {{ $t("reviewStars.description") }}
      </span>
    </div>
  </s-alert>
</template>

<script setup lang="ts">
import { faCircleStar } from "@fortawesome/pro-solid-svg-icons";

import { SAlert, SReviewScoreAtom } from "@solvari/common-fe";

import { useFeatureFlagStore } from "@/plugins/store/featureFlags";
const featureFlagStore = useFeatureFlagStore();
</script>

<style lang="postcss">
.s-form-review-stars {
  @apply w-fit;

  &__content {
    @apply flex flex-col gap-3;
    @screen 500 {
      @apply flex-row;
    }
  }
}
</style>
